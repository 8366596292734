import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack } from "@fluentui/react";
import { SquareRegular, ShieldLockRegular, ArrowRepeatAll16Regular, ErrorCircleRegular, FastForwardRegular, ChevronDoubleRightRegular, TriangleRightRegular} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import ReactDOM from 'react-dom';

import styles from "./Chat.module.css";
import layoutStyles from "../layout/Layout.module.css";
import ansStyles from "../../components/Answer/Answer.module.css"
import { parseAnswer } from "../../components/Answer/AnswerParser";
import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    DownloadFileApi,
    Conversation,
    historyClear,
    ErrorMessage,
    getGreetingMessage,
    getApplicationInfo,
    FrontendSettings,
    AskResponse
} from "../../api";
import { useTranslation, useLanguage } from "../../state/LanguageProvider";
import { useChatContext } from '../../state/ChatContextProvider';
import { ChatSkeleton } from "./ChatSkeleton";
import { Answer } from "../../components/Answer";
import { Unauthenticated } from "../unauthenticated/Unauthenticated";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { trackMetrics, trackEvent, trackTrace, trackException } from "../../api/telemetric";
import { isEmpty } from "lodash-es";
import FileSaver from 'file-saver';
import ChatContextSelection from "../../components/ChatContext/ChatContextSelection";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = () => {
    const lastQuestionRef = useRef<string>("");
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChatbotLoading, setIsChabotLoading] = useState<boolean>(true);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const [isCitationPanelVisible, setIsCitationPanelVisible] = useState<boolean>(false);
    const [isContinueGeneratingVisible, setIsContinueGeneratingVisible] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [sasToken, setSASToken] = useState<string>("");
    const [docRef, setDocRef] = useState<string>("");
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const citationPanelRef = useRef<HTMLDivElement>(null);
    const frontEndSettings: FrontendSettings = appStateContext?.state.frontendSettings!;
    const isChatSettingsEnabled = frontEndSettings?.CHAT_SETTINGS_ENABLED || false;
    const { getTranslation } = useTranslation();
    const { language} = useLanguage();
    const province: string = appStateContext?.state.province ?? '';
    const { chatContextKey, contextExists} = useChatContext();

    // Set up an effect to focus on the element when the component mounts or when Citations Panel conditions are met
    useEffect(() => {
      if (isCitationPanelOpen && activeCitation && citationPanelRef.current) {
        citationPanelRef.current.focus();
      }
    }, [isCitationPanelOpen, activeCitation]);

    const notInContextResponse = [
        "Sorry, this information is not mentioned in the retrieved documents",
        "The requested information is not available in the retrieved data.",
        "The requested information is not available in the retrieved data. Please try another query or topic.",
        "The requested information is not found in the retrieved data. Please try another query or topic.",
        "Sorry"];

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
    }

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    const [greetingMessage, setGreetingMessage] = useState<string[]>([]);

    const getInitialMessage = async () => {
        const { content } = await getGreetingMessage(language)
        // console.log("app name", import.meta.env.VITE_APP_NAME)
        // console.log("app name",JSON.stringify(import.meta.env))
        setGreetingMessage(content.split('|'))
    }

    const getUserInfoList = async () => {
        const userInfoList = await getUserInfo();
        const application = await getApplicationInfo(language);
        if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1" && !application.allowAnonymousUser) {
            setShowAuthMessage(true);
            setIsChabotLoading(false);
        }
        else {
            setShowAuthMessage(false);
            setIsChabotLoading(false);
        }
    }

    let assistantMessage = {} as ChatMessage;
    let toolMessage = {} as ChatMessage
    let assistantContent = "";

    const processContinueGeneratingAssistantMessage = (resultMessage: ChatMessage, conversationId?: string) => {

        if (resultMessage.role === ASSISTANT) {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                if (!assistantContent){
                    assistantContent = updatedMessages[updatedMessages.length - 1].content + " ";
                }
                
                assistantContent += resultMessage.content;
                assistantMessage = { ...resultMessage, content: assistantContent };

                updatedMessages[updatedMessages.length - 1] = assistantMessage
                return updatedMessages; 
            });
        }
    };

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const makeApiContinueRequestWithoutCosmosDB = async () => {

        const userMessage: ChatMessage = messages[messages.length - 3];
        const question = userMessage.content;
        const conversationId = userMessage.id;

        setIsLoading(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        let conversation = appStateContext?.state?.currentChat
        if (!conversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            return;
        }

        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            lang: language,
            province: province,
            index: chatContextKey,
            continue_generating: true
        };

        let result = {} as ChatResponse;
        try {
            const response = await conversationApi(request, abortController.signal);

            if (response?.body) {

                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            result.choices[0].messages.forEach((resultMessage) => {
                                resultMessage.id = uuid();
                                resultMessage.date = new Date().toISOString();
                            })
                            
                            result.choices[0].messages.forEach((resultObj) => {
                                processContinueGeneratingAssistantMessage(resultObj);
                            })
                            runningText = "";
                        }
                        catch { }
                    });
                }                   
                setIsContinueGeneratingVisible(result.continue_generating)
                
                // Set the langfuse trace id 
                assistantMessage.langfuseTraceId = result.langfuse_trace_id;

                const updatedMessages = [...conversation.messages];
                let assistantMessageIndex = updatedMessages.length -1
                updatedMessages[assistantMessageIndex] = assistantMessage;

                const updatedConversation = { ...conversation, messages: updatedMessages };
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: updatedConversation });
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                console.error(e);
                trackEvent(`Failed Question`,{ "Question":question, "Response": `${result.error?.message ||  errorMessage}`})
                trackException(`Failed Request ${question} Reason :${result.error?.message ||  errorMessage}`)
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            console.log("4-! appstate context before calling finally ", appStateContext?.state?.currentChat )
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const makeApiRequestWithoutCosmosDB = async (continue_generating: boolean, question: string, conversationId?: string) => {
        lastQuestionRef.current = question;
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };
        trackEvent(`Question`,userMessage)

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else if (!continue_generating) {
                conversation.messages.push(userMessage);
            }
        }
        if (!continue_generating){
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setMessages(conversation.messages)
        }

        trackMetrics("Question",1, userMessage)
        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            lang: language,
            province: province,
            index: chatContextKey,
            continue_generating: continue_generating
        };

        let result = {} as ChatResponse;
        let responseContent = "";
        try {
            const response = await conversationApi(request, abortController.signal);

            if (response?.body) {

                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            result.choices[0].messages.forEach((resultMessage) => {
                                resultMessage.id = uuid();
                                resultMessage.date = new Date().toISOString();
                            })
                            if(!isEmpty(assistantMessage.content)){
                                setShowLoadingMessage(false);
                            }
                            
                            result.choices[0].messages.forEach((resultObj) => {
                                processResultMessage(resultObj, userMessage, conversationId);
                            })
                            runningText = "";
                        }
                        catch { }
                    });
                }
                
                setIsContinueGeneratingVisible(result.continue_generating);

                // Set the langfuse trace id 
                assistantMessage.langfuseTraceId = result.langfuse_trace_id;
                conversation.messages.push(toolMessage, assistantMessage)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, toolMessage, assistantMessage]);

                const citations = parseCitationFromMessage(toolMessage)
                const hasNoResponseFromContext = notInContextResponse.some(str => str.includes(assistantMessage.content));
                const additionalProperties = { "Question":question, "Response": assistantMessage.content};
                const answerResponse: AskResponse = {
                    answer: assistantMessage.content,
                    citations: citations,
                    question: question,
                    answerId: assistantMessage.id,
                    isLastAnswer: isLastMessage(assistantMessage.id)
                }
                const parsedAnswer = parseAnswer(answerResponse)
                if(parsedAnswer.citations.length == 0) {
                    trackEvent(`No Results`,additionalProperties)
                    trackTrace(`No Results ${question} Reponse :${assistantMessage.content}`, additionalProperties)
                    trackMetrics("No Results", 1, additionalProperties);
                }
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                console.error(e);
                trackEvent(`Failed Question`,{ "Question":question, "Response": `${result.error?.message ||  errorMessage}`})
                trackException(`Failed Request ${question} Reason :${result.error?.message ||  errorMessage}`)
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id) {
            let response = await historyClear(appStateContext?.state.currentChat.id, language)
            appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
            appStateContext?.dispatch({ type: 'DELETE_CHAT_HISTORY' });

            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setMessages([]);
        setClearingChat(false);
        setIsContinueGeneratingVisible(false);
    };

    useEffect(() => {
        clearChat();
        getInitialMessage();
    }, [language])

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done)
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
        setIsContinueGeneratingVisible(false);
    }

    const continueGenerating = () => {
        setIsContinueGeneratingVisible(false);
        makeApiContinueRequestWithoutCosmosDB();
    }

    const regenerateResponse = () => {
        const quest = messages[messages.length - 3];
        messages.splice(-3);
        let conversation: Conversation | null | undefined;
        conversation = appStateContext?.state?.currentChat;
        setMessages(messages);
        
        if(conversation){
            conversation.messages = messages;
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        }
        
        makeApiRequestWithoutCosmosDB(false, quest['content'], quest['id'],)
    }

    const isLastMessage = (answerId: string) => {
        if (messages.length > 0 && (answerId == messages[messages.length-1].id)) {
            return true;
        }
        return false;
    }

    const isValidURL = (url:string) => {
        // Regular expression for URL validation
        var res = url.match(/(http(s)?:\/\/.)/g);
        if(res == null)
            return false;
        else
            return true;
    }
    const downloadSource = async (activeCitation: any) => {
        if (activeCitation.url && isValidURL(activeCitation.url)) {
            window.open(activeCitation.url, "_blank");
        }
        else{
            const res = await DownloadFileApi(activeCitation.url);
            if(res.status == 200){
                const sourceBlob = await res.blob()
                FileSaver.saveAs(sourceBlob, activeCitation.filepath);
            } 
        }
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useEffect(() => {
        getUserInfoList();
        getInitialMessage();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsCitationPanelVisible(isCitationPanelOpen);
        }, 300);
    }, [isCitationPanelOpen]);

    useEffect(() => {
        setTimeout(() => {
            setIsCitationPanelOpen(isCitationPanelVisible);
        }, 300);
    }, [isCitationPanelVisible]);

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core") && isValidURL(citation.url)) {
            window.open(citation.url, "_blank");
        } else {
            setActiveCitation(citation);
            setIsCitationPanelOpen(true);
        }
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const parseQuestion =(message: ChatMessage) => {
        if (message?.role && message?.role === "user") {
            return message.content
        }
        return '';
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat
    }

    const portalTarget = document.getElementById('header-portal-container');

    return (
        <>
            {isChatbotLoading ? (
                <ChatSkeleton />
            ) : (
            <>
                {showAuthMessage ? (
                    <Unauthenticated />
                ) : (
                    <div className={styles.container} role="main">

                        <Stack horizontal className={styles.chatRoot}>
                            <div className={styles.chatContainer}>
                                {!lastQuestionRef.current ? (

                                    <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                        {greetingMessage.map(m => (
                                            <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{m}</p>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        ))}
                                        {(isChatSettingsEnabled && contextExists) && (<ChatContextSelection/>)}
                                    </div>
                                ) : (
                                    <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                        {greetingMessage.map(m => (
                                            <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{m}</p>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        ))}
                                        {messages.map((answer, index) => (

                                            <>
                                                {answer.role === "user" ? (
                                                    <div className={styles.chatMessageUser} tabIndex={0}>
                                                        <Stack className={ansStyles.userMessageContainer}>
                                                            <Stack.Item className={styles.chatMessageUserMessage}>
                                                                <p>{answer.content}</p>
                                                            </Stack.Item>
                                                        </Stack>
                                                    </div>

                                                ) : (
                                                    answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                        <Answer
                                                            answer={{
                                                                answer: answer.content,
                                                                citations: parseCitationFromMessage(messages[index - 1]),
                                                                question: parseQuestion(messages[index - 2]),
                                                                langfuseTraceId: answer.langfuseTraceId,
                                                                answerId: answer.id,
                                                                isLastAnswer: isLastMessage(answer.id)
                                                            }}
                                                            onCitationClicked={c => onShowCitation(c)}
                                                            onRegenerateClicked={regenerateResponse}
                                                        />
                                                    </div> : answer.role === "error" ? <div className={styles.chatMessageError}>
                                                        <Stack horizontal className={styles.chatMessageErrorContent}>
                                                            <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                            <span>Error</span>
                                                        </Stack>
                                                        <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                    </div> : null
                                                )}
                                            </>
                                        ))}
                                        {showLoadingMessage && (
                                            <>
                                                <div className={styles.chatMessageGpt}>
                                                    <Answer
                                                        answer={{
                                                            answer: getTranslation('generating_answer' ),
                                                            citations: [],
                                                            question: '',
                                                            langfuseTraceId: '',
                                                            answerId: '',
                                                            isLastAnswer: false
                                                        }}
                                                        onCitationClicked={() => null}
                                                        onRegenerateClicked={() => null}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div ref={chatMessageStreamEnd} />
                                    </div>
                                )}

                                <Stack horizontal className={styles.chatInput}>
                                    {isLoading ? (
                                        <Stack
                                            horizontal
                                            className={styles.stopGeneratingContainer}
                                            role="button"
                                            aria-label= {getTranslation('stop_generating' )}
                                            tabIndex={0}
                                            onClick={stopGenerating}
                                            onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                        >
                                            <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                                            <span className={styles.stopGeneratingText} aria-hidden="true">{getTranslation('stop_generating' )}</span>
                                        </Stack>
                                    ) : (
                                        <>
                                        {isContinueGeneratingVisible && 
                                            (
                                            <Stack className={styles.centerContainer}>
                                                <Stack
                                                    horizontal
                                                    className={styles.continueGeneratingContainer}
                                                    role="button"
                                                    aria-label={getTranslation('continue_generating')}
                                                    tabIndex={0}
                                                    onClick={continueGenerating}
                                                    onKeyDown={e => (e.key === "Enter" || e.key === " ") ? continueGenerating() : null}
                                                >
                                                    <FastForwardRegular className={styles.continueGeneratingIcon} aria-hidden="true" />
                                                    <span className={styles.continueGeneratingText} aria-hidden="true">
                                                        {getTranslation('continue_generating')}
                                                    </span>
                                                </Stack>
                                            </Stack>
                                            )
                                        }
                                        </>    
                                    )}

                                    {!isLoading && messages.length > 0 && (
                                        <>
                                            {portalTarget && ReactDOM.createPortal(
                                                <Stack onClick={clearChat} horizontal tokens={{ childrenGap: 4 }}>
                                                    <Stack className={styles.clearSessionContainer}>
                                                        <CommandBarButton
                                                            role="button"
                                                            title={getTranslation('clear_chat' )}
                                                            styles={{
                                                                root: {
                                                                    color: '#FFFFFF',
                                                                    background: "#4b286d",
                                                                    cursor: "pointer",
                                                                    minWidth: "unset",
                                                                },
                                                                icon: {
                                                                    color: '#FFFFFF',
                                                                },
                                                                iconHovered: {
                                                                    color: '#FFFFFF',
                                                                },
                                                            }}
                                                            className={styles.clearChatNoCosmos}
                                                            iconProps={{
                                                                 iconName: 'Delete',
                                                                 styles: {
                                                                    root: {
                                                                      fontSize: '12px',
                                                                    },
                                                                  },
                                                            }}                                                
                                                            aria-label = {getTranslation('clear_chat' )}
                                                        />
                                                        <Dialog
                                                            hidden={hideErrorDialog}
                                                            onDismiss={handleErrorDialogClose}
                                                            dialogContentProps={errorDialogContentProps}
                                                            modalProps={modalProps}
                                                        >
                                                        </Dialog>
                                                    </Stack>
                                                    <label className={layoutStyles.iconLabel}> {getTranslation('clear_chat' )} </label>
                                                </Stack>
                                            ,
                                              portalTarget
                                            )}
                                        </>)
                                    }

                                    <QuestionInput
                                        clearOnSend
                                        placeholder= {getTranslation('chat_input' )}
                                        disabled={isLoading}
                                        onSend={(question, id) => {
                                            makeApiRequestWithoutCosmosDB(false, question, id)
                                        }}
                                        conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                                    />
                                </Stack>
                            </div>
                            {/* Citation Panel */}
                            {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                                <Stack.Item className={`${styles.citationPanel} ${isCitationPanelVisible ? styles.visiblePanel : styles.hiddenPanel}`} role="tabpanel" aria-label={getTranslation('citations_panel')}>
                                    <Stack aria-label={getTranslation('citations_panel_header_container')} horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                        <span aria-label={getTranslation('citations')} className={styles.citationPanelHeader}>{getTranslation('citations')}</span>
                                        <span title={getTranslation('download')} className={styles.downloadSource} >
                                            <IconButton iconProps={{ iconName: 'Download' }} aria-label={getTranslation('download_source')} onClick={() => downloadSource(activeCitation)} />
                                        </span>
                                        <IconButton iconProps={{ iconName: 'Cancel' }} aria-label={getTranslation('close_citations_panel')} onClick={() => setIsCitationPanelVisible(false)} />
                                    </Stack>
                                    <div className={styles.citationPanelContentContainer} ref={citationPanelRef} tabIndex={0} >
                                        <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
                                        <div tabIndex={0}>
                                            <ReactMarkdown
                                                linkTarget="_blank"
                                                className={styles.citationPanelContent}
                                                children={activeCitation.content}
                                                remarkPlugins={[remarkGfm]}
                                                rehypePlugins={[rehypeRaw]}
                                            />
                                        </div>
                                    </div>
                                </Stack.Item>
                            )}
                        </Stack>
                        <div>
                            {<p className={styles.chatFooter}>{getTranslation('pii_warning')}</p>}
                        </div>
                    </div>
                )}
            </>
            )}
        </>
    );
};

export default Chat;
