import { UserInfo, ConversationRequest, ChatMessage, ApplicationInfo, FrontendSettings, Language } from "./models";

export const frontendSettings = async (): Promise<Response | FrontendSettings | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages,
            lang: options.lang,
            province: options.province,
            index: options.index,
            continue_generating: options.continue_generating
        }),
        signal: abortSignal
    });

    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}

export async function DownloadFileApi(url: string): Promise<Response> {
    const response = await fetch("/downloadfile", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            url: url
        })
    });
    return response;    
}

export const historyClear = async (convId: string, lang: Language) : Promise<Response> => {
    const response = await fetch(`/history/clear?lang=${lang}`, {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export async function getGreetingMessage(lang: Language): Promise<ChatMessage> {
    const response = await fetch(`/getGreetingMessage?lang=${lang}`);
    if (!response.ok) {
        console.log("No welcome message configured.")
        return {role:'system', content:'', id:'',date:''};
    }

    const messages = await response.json();
        
    return messages;
}

export async function getApplicationInfo(lang: Language): Promise<ApplicationInfo> {
    const response = await fetch(`/applicationInfo?lang=${lang}`);
    if (!response.ok) {
        return {role:'system', allowAnonymousUser:false};
    }

    const messages = await response.json();
        
    return messages;
}

export async function getTranslations(lang: Language): Promise<Record<string, string>> {
    const response = await fetch(`/getTranslations?lang=${lang}`);
    if (!response.ok) {
        console.error("Failed to fetch translations.");
        return {};
    }

    const translations = await response.json();
    return translations;
}

export async function getChatContexts(lang: Language): Promise<Record<string, string>> { 
    const response = await fetch(`/getChatContexts?lang=${lang}`);
    if (!response.ok) {
        console.error("Failed to fetch context.");
        return {};
    }
    const contexts = await response.json();
    return contexts;  
}

