const provinces: Record<string, string> = {
    nl: "Newfoundland and Labrador (NL)",
    pe: "Prince Edward Island (PEI)",
    ns: "Nova Scotia (NS)",
    nb: "New Brunswick (NB)",
    qc: "Quebec (QC)",
    on: "Ontario (ON)",
    mb: "Manitoba (MB)",
    sk: "Saskatchewan (SK)",
    ab: "Alberta (AB)",
    bc: "British Columbia (BC)",
    yt: "Yukon (YT)",
    nt: "Northwest Territories (NT)",
    nu: "Nunavut (NU)",
};

export const getQueryParam = (param: string): string | null => {
    const queryParam = new URLSearchParams(window.location.search).get(param);
    return queryParam ? queryParam.toLowerCase() : null;
};

export const getProvince = (): string => {
    const code = getQueryParam('province') || ''
    return code ? provinces[code] : ''
}

export const isValidLanguage = (lang: string | null): boolean => {
    return lang === 'en-ca' || lang === 'fr-ca';
};
