import React, { useState, useContext } from 'react';
import { Panel, PanelType, ChoiceGroup, IChoiceGroupOption, IconButton, Overlay, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation } from "../../../state/LanguageProvider";
import styles from './MobileChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';

interface MobileChatSettings {
    isMultiContext: boolean;
    isMultiLang: boolean;
}

const MobileChatSettings: React.FC<MobileChatSettings> = ({isMultiLang, isMultiContext}) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const { chatContextKey, setChatContextKey, contextExists, availableChatContexts } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();
    
    const chatContextOptions: IChoiceGroupOption[] = Object.entries(availableChatContexts).map(([key, text]) => ({
        key, text 
    }));

    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const openPanel = () => setIsPanelOpen(true);
    const closePanel = () => setIsPanelOpen(false);

    return (
        <div className={styles.chatSettingsButtonContainer}>
            <IconButton
                iconProps={{ iconName: 'Settings' }}
                title="Settings"
                ariaLabel={getTranslation("settings")}
                onClick={openPanel}
                className={commonStyles.chatSettingsButton}
            />

            <Panel
                isOpen={isPanelOpen}
                onDismiss={closePanel}
                type={PanelType.customNear}
                customWidth="80%"
                isLightDismiss
                className={styles.chatSettingsPanel}
                onRenderNavigationContent={() => (
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel={getTranslation("close_panel")}
                        onClick={closePanel}
                        className={styles.closeIcon}
                    />
                )}
            >
                <div className={commonStyles.panelHeader}>
                    <IconButton
                        iconProps={{ iconName: 'Settings' }}
                        ariaLabel={getTranslation("settings")}
                        className={`${commonStyles.chatSettingsButton} ${commonStyles.whiteBackground}`}
                        disabled
                    />
                    <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                </div>

                {isMultiLang && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("language")}
                        selectedKey={language}
                        onChange={(_, option) => setLanguage(option?.key as Language)}
                        options={languageOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

                {(isMultiContext && contextExists) && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("help_me_with")}
                        selectedKey={chatContextKey}
                        onChange={(_, option) => setChatContextKey(option?.key as string)}
                        options={chatContextOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

            </Panel>

            {isPanelOpen && <Overlay />}
        </div>
    );
};

export default MobileChatSettings;
