import React, { useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, IconButton, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation } from "../../../state/LanguageProvider";
import styles from './DesktopChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';

interface DesktopChatSettingsProps {
    isMultiContext: boolean;
    isMultiLang: boolean;
}

const DesktopChatSettings: React.FC<DesktopChatSettingsProps> = ({isMultiLang, isMultiContext}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const { chatContextKey, setChatContextKey, contextExists, availableChatContexts } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();
    
    const chatContextOptions: IChoiceGroupOption[] = Object.entries(availableChatContexts).map(([key, text]) => ({
        key, text 
    }));

    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className={`${styles.desktopChatSettingsContainer} ${isExpanded ? styles.desktopExpanded : styles.desktopCollapsed}`}>
            <div className={styles.desktopToggleButtonContainer}>
                <IconButton
                    iconProps={{ iconName: isExpanded ? 'ChevronLeft' : 'ChevronRight' }}
                    title="Toggle Settings"
                    ariaLabel={getTranslation("toggle_settings")}
                    onClick={toggleExpand}
                    className={styles.desktopToggleButton}
                />
            </div>

            {isExpanded && (
                <div className={styles.desktopSettingsContent}>
                    <div className={commonStyles.panelHeader}>
                        <IconButton
                            iconProps={{ iconName: 'Settings' }}
                            ariaLabel={getTranslation("settings")}
                            className={commonStyles.chatSettingsButton}
                            disabled
                        />
                        <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                    </div>

                    {isMultiLang && (
                    <div className={commonStyles.choiceGroupContainer}>
                        <ChoiceGroup
                            label={getTranslation("language")}
                            selectedKey={language}
                            onChange={(_, option) => setLanguage(option?.key as Language)}
                            options={languageOptions}
                            styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                        />
                    </div>)}

                    {(isMultiContext && contextExists) && (
                    <div className={commonStyles.choiceGroupContainer}>
                        <ChoiceGroup
                            label={getTranslation("help_me_with")}
                            selectedKey={chatContextKey}
                            onChange={(_, option) => setChatContextKey(option?.key as string)}
                            options={chatContextOptions}
                            styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                        />
                    </div>)}
                </div>
            )} 
        </div>
    );
};

export default DesktopChatSettings;
